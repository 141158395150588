<ng-container *ngIf="isCssReady">
  <div
    class="transition-all hover:scale-[1.1] fixed top-1 px-3 py-1 flex items-center gap-3 cursor-pointer mobile:top-3"
    (click)="close()">
    <ig-image src="https://iti-images.s3.amazonaws.com/imgs/back-arrow.png"
      cls="w-[60px] h-[60px] mobile:w-[50px] mobile:h-[50px]"></ig-image>
    <span class="text-xl font-semibold text-white mobile:text-lg">BACK</span>
  </div>

  <div class="create-wrapper h-full flex justify-center items-center relative">
    <div
      class="h-full flex shadow-2xl w-4/5 max-w-[1400px] mobile:block mobile:w-full mobile:h-full mobile:overflow-y-auto mobile:overflow-x-hidden">
      <section class="left-wrapper !w-1/3 relative hidden xl:block">
        <div class="w-full h-full bg-black opacity-20 absolute"></div>
        <div class="w-full h-full absolute text-white text-center pt-20 flex flex-col justify-between">
          <div>
            <div class="text-3xl font-semibold">Welcome</div>
            <div class="text-base mt-4 2xl:text-lg">
              Thank you for submitting your place!
            </div>
          </div>

          <div class="mb-24">
            <div class="text-sm max-w-[80%] m-auto">
              <ul>
                <li class="text-left list-disc">
                  Please provide as much information about your business as
                  possible.
                </li>
                <li class="text-left list-disc">
                  Allow at least 5 business days after submitting your business
                  for review and approval.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div class="w-full bg-white rounded-r-2xl pl-9 pr-4 py-6 text-gray-600 xl:w-2/3 mobile:px-6 mobile:pb-16">
        <div class="text-2xl">Please fill in your place info</div>
        <div class="text-sm text-gray-400">
          Complete all required fields marked with an * to submit your place for
          approval
        </div>
        <section class="w-full mt-4 py-2 h-[calc(100%-4rem)] overflow-y-auto pr-5 mobile:pr-0">
          <ig-field [control]="form.get('name')" label="Title" icon="stars"></ig-field>
          <ig-address-input [control]="form.get('address')" class="block mobile:mb-6"></ig-address-input>
          <ig-field [control]="form.get('website')" label="Your Website (if applicable)" icon="link"></ig-field>
          <ig-field [control]="form.get('facebookLink')" label="Facebook Page Link" icon="link"></ig-field>
          <ig-field [control]="form.get('category')" label="Category" icon="list" [isReadOnly]="true"
            (click)="showEditCategoryModal()"></ig-field>
          <ig-field [control]="form.get('phone')" label="Phone Number" icon="phone"></ig-field>
          <ig-field [control]="form.get('description')" mode="textarea" label="Description"
            icon="more_horiz"></ig-field>
          <ig-field [control]="form.get('imageLink')" label="Image Urls" icon="image" [hint]="hint">
          </ig-field>
          <div class="text-sm text-gray-500 relative">or</div>
          <div class="flex justify-between mt-1 mobile:mt-6">
            <div class="w-[19%]" *ngFor="let i of [].constructor(imageUploaders)">
              <ig-image-uploader [triggerRemoveImage]="imageLinks" (uploaded)="onImageUploaded($event)"
                (remove)="onRemoveImage($event)"></ig-image-uploader>
            </div>
          </div>
          <div class="owner-contact-info">
            <span>Owner Contact Information </span>
          </div>
          <hr />
          <span class="owner-contact-description">
            This information will not be listed on the public listing, but is provided to DMO if they need to reach out
            for further information.
          </span>
          <ig-field [control]="form.get('ownerEmail')" label="Owner Email Address" icon="mail"
            class="block mt-2"></ig-field>
          <ig-field [control]="form.get('ownerPhone')" label="Owner Phone Number" icon="phone"></ig-field>
          <mat-checkbox class="mb-2" [control]="form.get('hasCopyrights')" [(ngModel)]="hasCopyrights"
            *ngIf="isUsingLink || (uploadedImages && uploadedImages.length > 0)">
            Please note that any images uploaded or used in this software must either be royalty-free
            or owned by
            the client. By uploading or using an image, you
            acknowledge that you have the necessary rights to use it and that you will be solely responsible for any
            copyright infringement or other legal issues that may arise from the use of the image. We strongly advise
            our
            users to exercise due diligence and ensure that they have the necessary rights to use any images uploaded or
            used in this software.
          </mat-checkbox>
          <ig-button (trigger)="submit($event)" cls="w-[160px] mb-6 mobile:w-full" [isAsync]="true"
            [disabled]="isFormInValid"
            successText="Thank you for submitting your place! Please allow at least 5 business days for review and approval.">
            SUBMIT PLACE
          </ig-button>
        </section>
      </div>
    </div>
  </div>
</ng-container>