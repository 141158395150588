import { VisitorService } from './../../../shared/services/visitor.service';
import {
  Component,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/common/components/base.component';
import { ConfigService } from 'src/app/common/services/config/config.service';
import { SeoService } from 'src/app/common/services/seo.service/seo.service';
import { isEmptyArray } from 'src/app/common/utils/object.extensions';
import { similarity } from 'src/app/common/utils/string.extensions';
import { EventTimeService } from 'src/app/modules/event-calendar/services/event.time.service';
import { VisitorEventService } from 'src/app/modules/event-calendar/services/visitor.event.service';
import { VisitorPlaceService } from 'src/app/modules/event-calendar/services/visitor.place.service';
import { GoogleMapComponent } from 'src/app/modules/shared/components/google.map/google.map.component';
import { ImMessageBoxComponent } from 'src/app/modules/shared/components/im.message.box/im.message.box.component';
import { IMapMarkerWithCustomData } from 'src/app/modules/shared/models/place.marker';
import {
  AIModel,
  IAIResponse,
  ITravelAssistantMessage,
} from 'src/app/modules/shared/models/travel.assistant';
import { TravelBuddySettings } from 'src/app/modules/shared/models/travel.buddy.settings';
import { VisitorEvent } from 'src/app/modules/shared/models/visitor.event';
import { VisitorPlace } from 'src/app/modules/shared/models/visitor.place';
import { GoogleMapService } from 'src/app/modules/shared/services/google.map.service';

@Component({
  selector: 'ig-ai-buddy-place-listing',
  templateUrl: './ai.buddy.place.listing.component.html',
  styleUrls: ['./ai.buddy.place.listing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AiBuddyPlaceListingComponent
  extends BaseComponent
  implements OnInit
{
  @HostBinding('class') hostClass = 'ig-ai-buddy-place-listing';

  left: string;
  right: string;
  top: string;
  bottom: string;

  aiModel: AIModel = 'chatgpt-assistant';
  /* the content in the landing page is showing up one by one */
  showLandscreenContent: boolean[] = [];
  places: (VisitorPlace & { ImGoingLink: string })[] = [];
  events: (VisitorEvent & { ImGoingLink: string })[] = [];
  msg: string = '';
  msgHistory: ITravelAssistantMessage[] = [];
  isScreenExpanded = false;

  isShowMap = false;
  mapMarkers: IMapMarkerWithCustomData<VisitorPlace | VisitorEvent>[] = [];
  center: google.maps.LatLngLiteral = null;
  directionLocations: { id; name; address; lat; lng; stopover }[] = [];
  directionType: 'normal' | 'optimized' | undefined = undefined;
  @ViewChild('map', { static: false }) googleMap: GoogleMapComponent;

  /*
    for AI answer, will match the suggested places with ImGoing places
    AI suggested place names are in bold
    the following words do not need to match */
  noMatchForWords = [
    'Mid-morning',
    'Morning',
    'Midday',
    'Lunch',
    'Late Afternoon',
    'Afternoon',
    'Early Evening',
    'Evening',
    'Dining',
  ];

  /* Sometimes OpenAI would give us some places that are not in ImGoing, then we search them in Google (at server side) and add to this list, to use them on the map */
  searchedPlaces = [];

  public target = '';
  public intro = '';
  public question1 = `What are some of the must-see attractions in {target}?`;
  public question2 = `Please suggest a 2-day itinerary in {target}.`;
  public travelBuddySettings: TravelBuddySettings;

  @Input() paramFromParent: string;

  @Input() template: TemplateRef<any>;
  @Input() context: any;

  @ViewChild('immessager', { static: false }) immessager: ImMessageBoxComponent;

  retryWhenAnswerContains = ['unable to access'];

  constructor(
    public activatedRoute: ActivatedRoute,
    private _visitorService: VisitorService,
    private _googleMapService: GoogleMapService,
    private _placeService: VisitorPlaceService,
    private _eventService: VisitorEventService,
    private _eventTimeService: EventTimeService,
    private _seoService: SeoService,
    private _renderer: Renderer2
  ) {
    super({ activatedRoute });

    _placeService.getDestinationPlaceFeeds(this.hubName).subscribe((places) => {
      this.places = places;
      console.log('places', places.length);
    });

    _eventService.getDestinationEventFeeds(this.hubName).subscribe((events) => {
      this.events = events;
      console.log('events', events.length);
    });

    for (let i = 1; i <= 20; i++) {
      this.noMatchForWords.push(`Day ${i}`);
    }

    if (this.frontendSettings?.ai?.travelBuddy?.cityName) {
      this.noMatchForWords.push(this.frontendSettings.ai.travelBuddy.cityName);
    }

    // set position of the travel buddy toggle button, which can be set from the embedding script
    this.left = this.getScriptValue('travel-buddy-left') || 'auto';
    this.right = this.getScriptValue('travel-buddy-right') || '48px';
    this.top = this.getScriptValue('travel-buddy-top') || 'auto';
    this.bottom = this.getScriptValue('travel-buddy-bottom') || '32px';
  }

  ngOnInit(): void {
    this.loadCustomSettings();
  }

  loadCustomSettings() {
    if (
      this.frontendSettings &&
      !this.frontendSettings.ai?.travelBuddy?.cityName
    ) {
      console.warn('Please set the city name');
    } else {
      this.target = this.frontendSettings?.ai?.travelBuddy?.cityName;
      const questions = this.frontendSettings?.ai?.travelBuddy?.ui?.questions;
      if (!isEmptyArray(questions)) {
        this.question1 = questions[0];
        if (questions.length > 1) {
          this.question2 = questions[1];
        } else {
          this.question2 = this.question2.replace('{target}', this.target);
        }
      } else {
        this.question1 = this.question1.replace('{target}', this.target);
        this.question2 = this.question2.replace('{target}', this.target);
      }

      this.travelBuddySettings = this.frontendSettings?.ai?.travelBuddy;
      this.intro =
        this.frontendSettings.ai.travelBuddy.ui.avatar.intro ||
        `I'm your personal AI-powered travel assistant. Simply ask me a question, and I will provide you with relevant travel information and suggestions for your next trip to ${this.target}!`;
    }
  }

  get hasIntroduction() {
    if (
      !this.travelBuddySettings ||
      !this.travelBuddySettings.ui.introduction
    ) {
      return false;
    }

    // check if any of the properties of tis.introduction are not null or empty
    for (const key in this.travelBuddySettings.ui.introduction) {
      if (this.travelBuddySettings.ui.introduction[key]) {
        return true;
      }
    }
    return false;
  }

  tryAgain() {
    this.gotoChat(this.msg, true);
  }

  async gotoChat(msg: string, isTryAgain = false) {
    if (!msg && !isTryAgain) {
      return;
    }
    this.immessager.gotoChat(
      msg?.replace('{{target}}', this.target),
      isTryAgain,
      this.processAnswer.bind(this)
    );
  }

  loginStatusChanged(status: boolean) {
    console.log('login status in ai.buddy.place.list', status);
  }

  keypress($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.gotoChat(this.msg);
    }
  }

  processAnswer(data: IAIResponse): IAIResponse {
    // data.answer = data.answer.replace(/\s+/g, ' ');  // only keep single blank
    // data.answer = data.answer.replace(/<br>\s+<br>/g, '<br><br>');
    // data.answer = data.answer.replaceAll('<br>-', '<br><br>-');  // add a line break before each list item
    // data.answer = data.answer.replace(/<br>\d+\./g, '<br><br>$&');  // add a line break before each list item
    // data.answer = data.answer.replace(/(<br>)+/g, '<br><br>'); // replace multiple line breaks with a single one
    // data.answer = data.answer.replace(/<\/h3><br><br>/g, '</h3><br>'); // replace multiple line breaks with a single one after h3 title (which is usually "DAY 1" if you ask for an itinerary)
    // data.answer = data.answer.replace(/<br><br><\/h3>/g, '<br></h3>'); // replace multiple line breaks with a single one after h3 title (which is usually "DAY 1" if you ask for an itinerary)
    // data.answer = data.answer.replace(/\s<br><br>\s/g, '<br><br>');
    // data.answer = data.answer.replace(/<br><br> - /g, ' - ');
    // data.answer = data.answer.replace(/: -/g, ' -');
    // data.answer = data.answer.replace(/<br><br> <\/p>/g, '<br></p>');

    data.answer = data.answer.replace(/&#39;/g, "'"); // to escape the symbols so that can match the event/place names
    data.answer = data.answer.replace(/&amp;/g, '&');
    data.answer = data.answer.replace(/&quot;/g, '"');

    let placeNames = data.placesMentioned; //data.answer.match(/<em>(.*?)<\/em>/g);  // match event/place name, which are in italic
    // let placeNamesUsingStrong = data.answer.match(/<strong>(.*?)<\/strong>/g);  // sometimes AI won't set the names in italic but in bold font
    console.log('names', placeNames);
    console.log('this.places', this.places.length);

    // placeNames = (placeNames || []).concat(placeNamesUsingStrong);   // match both names in italic and bold
    this.searchedPlaces = data.placeSearch;

    this.noMatchForWords.forEach((word) => {
      data.answer = data.answer.replaceAll(`${word}:`, `<b>${word}:</b>`);
    });

    this.isShowMap = false;
    this.mapMarkers = [];
    this.directionLocations = [];

    if (placeNames) {
      const contents = placeNames
        .filter((x) => x)
        .map((match) =>
          match.replace(/<\/?em>/g, '').replace(/<\/?strong>/g, '')
        );
      const nameForMatching = contents.map((x) => x.replaceAll('&#39;', "'"));
      console.log('nameForMatching', nameForMatching);

      for (let i = 0; i < nameForMatching.length; i++) {
        if (
          this.noMatchForWords.any(
            (x) =>
              nameForMatching[i].toLowerCase().replace(':', '') ===
              x.toLowerCase().replace(':', '')
          )
        ) {
          console.log(`ignored ${nameForMatching[i]}\n`);
          continue;
        }

        let place = this.places.find((x) => x.name === nameForMatching[i]);
        console.log(`place find for ${nameForMatching[i]}`, place);
        if (!place) {
          place = data.placeSearch?.find(
            (x) => similarity(x.name, nameForMatching[i]) > 0.8
          );
          if (place) {
            console.log(
              `--------- place found in placeSearch ${nameForMatching[i]}`,
              place
            );
            place = place['searchedPlace'];
          } else {
            let maxSimilarity = 0;
            let similarPlace = null;
            for (const p of this.places) {
              const nameSimilarity = similarity(p.name, nameForMatching[i]);
              if (nameSimilarity > maxSimilarity) {
                similarPlace = p;
                maxSimilarity = nameSimilarity;
              }
            }
            console.log('maxSimilarity', maxSimilarity);
            if (maxSimilarity >= 0.61) {
              place = similarPlace;
              console.log('similarPlace', place);
            } else {
              console.log('similarPlace -- ', similarPlace?.name);
            }
            console.log('\n');
          }
        }

        // let place = data.placeSearch?.find((x) => similarity(x.name, nameForMatching[i]) > 0.8);
        // if (place) {
        //   console.log(`************************* place found in placeSearch ${nameForMatching[i]}`, place);
        //   place = place.searchedPlace;
        // } else {
        //   place = this.places.find((x) => x.name === nameForMatching[i]);
        //   console.log(`place find for ${nameForMatching[i]}`, place);
        //   if (!place) {
        //     let maxSimilarity = 0;
        //     let similarPlace = null;
        //     for (const p of this.places) {
        //       const nameSimilarity = similarity(p.name, nameForMatching[i]);
        //       if (nameSimilarity > maxSimilarity) {
        //         similarPlace = p;
        //         maxSimilarity = nameSimilarity;
        //       }
        //     }
        //     console.log('maxSimilarity', maxSimilarity);
        //     if (maxSimilarity >= 0.61) {
        //       place = similarPlace;
        //       console.log('similarPlace', place);
        //     } else {
        //       console.log('similarPlace -- ', similarPlace?.name);
        //     }
        //     console.log('\n');
        //   }
        // }

        if (place && !this.directionLocations.any((x) => x.id === place._id)) {
          console.log(
            '-- placeNames[i]: ' + placeNames[i],
            `will be replaced by ${place.name}`
          );
          data.answer = data.answer.replace(placeNames[i] + ':', placeNames[i]);
          data.answer = data.answer.replace(
            `<strong>${placeNames[i]}</strong>:`,
            placeNames[i]
          );
          // replace all placeNames[i] with place.name
          data.answer = data.answer.replace(
            new RegExp(placeNames[i], 'g'),
            place.name
          );
          data.answer = data.answer.replace(
            place.name,
            `<a class="ig-ai-link ${
              place._id
            }" target="_blank" href="${this.generatePlaceLink(place)}">${
              place.name
            }</a>
            <div class="ig-ai-place ${place._id}">
              <img class="ig-ai-img" src="${
                place.cover.source ||
                this.frontendSettings.customSettings.logo.url
              }" alt="${place.name}">
              <div class="ig-ai-info">
                <div class="flex items-center gap-1 ${
                  !place.rating ? 'hidden' : ''
                }">
                  <span class="text-xs">${place.rating}</span>
                  <img src="${this._placeService.getRatingImg(
                    place.rating
                  )}" class="h-[14px]"></img>
                </div>
                <div class="text-xs">
                  ${this._placeService.getDisplayCategory(place)}
                </div>
              </div>
              <div class="text-xs">
                <div class="text-sm font-semibold mb-0.5">
                  ${place.name}
                </div>
                <div class="font-normal">${place.phone || ''}</div>
                <div class="font-normal">${place.website || ''}</div>
                <div class="font-normal">${place.address || ''}</div>
              </div>
            </div>`
          );

          // <img src="https://iti-images.s3.amazonaws.com/imgs/clock.png" class="w-3 h-3 mt-[1px]"><span class="underline">See Hours</span>

          this.mapMarkers.push(this.createMapMarkersForPlace(place));

          this.directionLocations.push({
            id: place._id,
            name: place.name,
            address: place.address,
            lat: place.lat,
            lng: place.lng,
            stopover: true,
          });
        }
      }
    }

    let hasEventsInResponse = false;
    let eventNames = data.eventsMentioned;

    if (eventNames && eventNames.length > 0) {
      console.log('--------------- Matching events ---------------');
      const contents = eventNames
        .filter((x) => x)
        .map((match) =>
          match.replace(/<\/?em>/g, '').replace(/<\/?strong>/g, '')
        );
      const eventNameForMatching = contents.map((x) =>
        x.replaceAll('&#39;', "'")
      );
      console.log('eventNameForMatching', eventNameForMatching);

      for (let i = 0; i < eventNameForMatching.length; i++) {
        if (
          this.noMatchForWords.any(
            (x) =>
              eventNameForMatching[i].toLowerCase().replace(':', '') ===
              x.toLowerCase().replace(':', '')
          )
        ) {
          console.log(`ignored ${eventNameForMatching[i]}\n`);
          continue;
        }

        let event = this.events.find((x) => x.name === eventNameForMatching[i]);
        console.log(`eventNames[i]: `, eventNames[i]);
        console.log(`event find for ${eventNameForMatching[i]}`, event);
        if (!event) {
          event = this.getSimilarEvent(eventNameForMatching[i]);

          // sometimes OpenAI may give us event name + at location (e.g., the original event name is "The Marshall Tucker Band" but OpenAI give us "The Marshall Tucker Band at the Great Ogeechee Music + Seafood Festival")
          // here is to remove the text after at and check again
          if (!event && eventNameForMatching[i].includes(' at ')) {
            const halfName = eventNameForMatching[i].split(' at ')[0];
            console.log(
              `eventNameForMatching[i] has at, half name ======= `,
              halfName
            );
            event = this.getSimilarEvent(halfName);
          }
          console.log('\n');
        }

        if (event && !this.directionLocations.any((x) => x.id === event._id)) {
          this._eventTimeService.processEventTimes(event);
          data.answer = data.answer.replace(eventNames[i] + ':', eventNames[i]);
          data.answer = data.answer.replace(
            eventNames[i],
            `<a class="ig-ai-link ${
              event._id
            }" target="_blank" href="${this.generateEventLink(event)}">${
              event.name
            }</a>
            <div class="ig-ai-place ${event._id}">
            <img class="ig-ai-img" src="${event.cover.source}" alt="${
              event.name
            }">
              <div class="text-xs">
                <div class="text-sm font-semibold mb-0.5">
                  ${event.name}
                </div>
                <div class="font-normal">${event.timeOneLine || ''}</div>
                <div class="font-normal">${event.address.address || ''}</div>
              </div>
            </div>
            `
          );
          data.answer = data.answer.replace(/(<br>)+/g, '<br><br>');
          data.answer = data.answer.replace(/<\/h3><br><br>/g, '</h3><br>'); // replace multiple line breaks with a single one after h3 title (which is usually "DAY 1" if you ask for an itinerary)
          data.answer = data.answer.replace(/<br><br><\/h3>/g, '<br></h3>'); // replace multiple line breaks with a single one after h3 title (which is usually "DAY 1" if you ask for an itinerary)
          hasEventsInResponse = true;

          this.mapMarkers.push(this.createMapMarkersForEvent(event));

          this.directionLocations.push({
            id: event._id,
            name: event.name,
            address: event.address,
            lat: event.address.lat,
            lng: event.address.lng,
            stopover: true,
          });
        }
      }
    }

    data.answer = data.answer.replace(/<p><\/p>/g, '');
    data.answer = data.answer.replace(/(<br>)+/g, '<br><br>');
    data.answer = data.answer.replace(/<\/h3><br><br>/g, '</h3><br>'); // replace multiple line breaks with a single one after h3 title (which is usually "DAY 1" if you ask for an itinerary)
    data.answer = data.answer.replace(/<br><br><\/h3>/g, '<br></h3>'); // replace multiple line breaks with a single one after h3 title (which is usually "DAY 1" if you ask for an itinerary)
    const h3Match = data.answer.match(/<h3>(.*?)<br>/g);
    if (h3Match) {
      for (const match of h3Match) {
        const index = data.answer.indexOf(match);
        if (
          !data.answer
            .substring(index, match.length + index + 6)
            .includes('</h3>')
        ) {
          data.answer = data.answer.replace(match, match + '</h3>');
        }
      }
    }

    // add a client website event widget link
    if (
      (hasEventsInResponse || data.askForEvents) &&
      this.frontendSettings.contacts.eventCalendarURL
    ) {
      const eventWidgetURL = this.frontendSettings.contacts.eventCalendarURL;
      const btn = `<a class="w-full h-8 rounded !text-white !no-underline flex justify-center items-center bg-blue-400 cursor-pointer mt-1" target="_blank" href="${eventWidgetURL}">View All Events</a>`;
      const moreEventsTips = [
        `Discover even more exciting events here:`,
        `Want to see what else is happening? Check out our event page:`,
        `Looking for more events? Click here:`,
        `Explore our full calendar of events for all the latest happenings in ${this.target}, click here:`,
        `For a complete list of events, please visit our event page:`,
      ];
      data.answer +=
        data.answer.trim().endsWith('<br><br></p>') ||
        data.answer.trim().endsWith('<br><br>')
          ? ''
          : '<br>';
      data.answer += `${moreEventsTips.getRandom()}<br>${btn}`;
    }

    if (data.askForItinerary && this.frontendSettings.contacts.moreToursURL) {
      const itineraryWidgetURL = this.frontendSettings.contacts.moreToursURL;
      const btn = `<a class="w-full h-8 rounded !text-white !no-underline flex justify-center items-center bg-orange-400 cursor-pointer mt-1" target="_blank" href="${itineraryWidgetURL}">View More Itineraries</a>`;
      const itineraryTips = [
        `Explore Our Itineraries! 🌟 Check out our Itinerary page for travel ideas!`,
        `Plan Your Perfect Trip ✈️ Find custom itineraries on our Itinerary page!`,
        `Need Travel Ideas? 🌍 Visit our Itinerary page for travel suggestions!`,
        `Your Next Adventure Awaits! 🗺️ See our Itinerary page for curated travel plans!`,
        `Dreaming of Your Next Trip? 🏞️ Discover itineraries on our Itinerary page!`,
      ];
      data.answer +=
        data.answer.trim().endsWith('<br><br></p>') ||
        data.answer.trim().endsWith('<br><br>')
          ? ''
          : '<br>';
      data.answer += `${itineraryTips.getRandom()}<br>${btn}`;
    }

    setTimeout(() => {
      const answer = Array.from(
        this.shadowRoot.querySelectorAll('.ig-im-answer')
      ).last();
      const links = answer.querySelectorAll('.ig-ai-link');
      console.log('links', links);
      if (links.length > 0) {
        this.isShowMap = true;

        setTimeout(() => {
          for (const link of Array.from(links)) {
            const itemId = Array.from(link.classList).last();
            let item =
              this.places.find((x) => x._id === itemId) ||
              this.events.find((x) => x._id === itemId);

            if (!item) {
              item = this.searchedPlaces.find(
                (x) => x.searchedPlace._id === itemId
              )?.searchedPlace;
            }

            this._renderer.listen(link, 'mouseover', () => {
              const marker = this.googleMap.mapMarkerObjs.find(
                (x) => x.getTitle() === itemId
              );

              if (this._visitorService.getItemType(item) === 'event') {
                this.googleMap.showEventInfoWindow(marker, {
                  ...marker,
                  data: item as VisitorEvent,
                } as IMapMarkerWithCustomData<VisitorEvent>);
              } else {
                this.googleMap.showPlaceInfoWindow(marker, {
                  ...marker,
                  data: item as VisitorPlace,
                } as IMapMarkerWithCustomData<VisitorPlace>);
              }
            });
          }

          this.adjustMapHeight();
        }, 1000);
      }
    }, 1000);

    return data;
  }

  getSimilarEvent(target: string) {
    let event = undefined;
    let maxSimilarity = 0;
    let similarEvent = null;
    for (const p of this.events) {
      const nameSimilarity = similarity(p.name, target);
      if (nameSimilarity > maxSimilarity) {
        similarEvent = p;
        maxSimilarity = nameSimilarity;
      }
    }
    console.log('maxSimilarity', maxSimilarity);
    if (maxSimilarity >= 0.58) {
      event = similarEvent;
      console.log('similarEvent', event);
    } else {
      console.log('similarEvent -- ', similarEvent?.name);
    }
    return event;
  }

  adjustMapHeight() {
    // adjust map height to match the left answers panel's height
    const answer = Array.from(
      this.shadowRoot.querySelectorAll('.ig-im-answer')
    ).last();

    setTimeout(() => {
      const size = answer.getBoundingClientRect();
      const mapArea = Array.from(
        this.shadowRoot.querySelectorAll('.ig-im-adding-expanded')
      ).last();
      if (mapArea) {
        (mapArea as HTMLElement).style.height = `${size.height}px`;
        const map = mapArea.querySelector('ig-google-map');
        if (map) {
          (map as HTMLElement).style.height = `${size.height}px`;
        }
      }
    });
  }

  drawDirectionRoute() {
    this.mapMarkers = [];
    this.directionLocations.forEach((marker, index) => {
      // for the yellow order marker
      this.mapMarkers.push({
        position: {
          lat: marker.lat,
          lng: marker.lng,
        },
        options: {
          icon: {
            url: 'https://imgoingcalendar.com/assets/imgs/tp-marker-2.png',
            // anchor: new google.maps.Point(30, 50),
            scaledSize: new google.maps.Size(25, 25),
          } as google.maps.Icon,
          zIndex: 10,
          // optimized: true,
        },
        label: {
          text: (++index).toString(),
          fontWeight: 'bold',
        },
      } as IMapMarkerWithCustomData<VisitorPlace>);
    });

    this.googleMap.drawDirectionRoute(this.directionLocations);
    this.directionType = 'normal';
  }

  getDirections() {
    let waypoints = [];
    for (const stop of this.directionLocations) {
      const address = stop.address.replaceAll(' ', '+').replaceAll('null,', '');
      waypoints.push(address);
    }

    // remove the last stop from waypoints
    const destination = waypoints.pop();

    let navigationUrl = ConfigService.config.google.navigationApiShort.replace(
      '${waypoints}',
      waypoints.join('|')
    );
    navigationUrl = navigationUrl.replace('${mode}', 'DRIVING');
    navigationUrl = navigationUrl.replace('${destination}', destination);

    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      console.log(`lat: ${lat}, lng: ${lng}`);

      const googleMapUrl =
        'http://www.google.com/maps/dir/?api=1&' +
        navigationUrl.replace('${GPS}', `${lat},${lng}`);
      window.open(googleMapUrl, '_blank');
    });
  }

  optimizeDirectionRouteByDistance() {
    const start = this.directionLocations[0];

    let waypoints = [start];
    while (waypoints.length < this.directionLocations.length) {
      const point = waypoints.last();
      const nearest = this.getNeareatPoint(
        point,
        this.directionLocations.filter(
          (x) => x != point && !waypoints.includes(x)
        )
      );
      waypoints.push(nearest);
    }

    console.log('waypoints in by distance', waypoints);

    this.googleMap.drawDirectionRoute(waypoints);

    this.mapMarkers = [];
    setTimeout(() => {
      waypoints.forEach((x, index) => {
        this.addMapMarker(x.lat, x.lng, index + 1, x.id);
      });
    });

    this.directionType = 'optimized';
  }

  getNeareatPoint(start, targetPoints: any[]) {
    let minDistance = 99999,
      nearestPoint = null;

    for (const point of targetPoints) {
      const distance = this._googleMapService.getDistanceFromLatLng(
        start.lat,
        start.lng,
        point.lat,
        point.lng
      );
      if (distance < minDistance && distance > 0) {
        minDistance = distance;
        nearestPoint = point;
      }
    }

    return nearestPoint;
  }

  optimizeDirectionRoute() {
    const start = this.directionLocations[0];
    let maxDistance = 0,
      farestPoint = null;
    for (const point of this.directionLocations) {
      const distance = this._googleMapService.getDistanceFromLatLng(
        start.lat,
        start.lng,
        point.lat,
        point.lng
      );
      if (distance > maxDistance) {
        maxDistance = distance;
        farestPoint = point;
      }
    }

    // farestPoint = this.directionLocations.find(
    //   (x) => x.name === 'The Olde Pink House'
    // );
    console.log('maxDistance', maxDistance);
    console.log('farestPoint', farestPoint.name);

    // move farestPoint to the last one of this.directionLocations
    this.directionLocations.splice(
      this.directionLocations.length - 1,
      0,
      this.directionLocations.splice(
        this.directionLocations.indexOf(farestPoint),
        1
      )[0]
    );

    this.googleMap.directionsResults$
      .pipe(takeUntil(this.isDestroyed))
      .subscribe(async (data) => {
        console.log('direction', data);

        this.mapMarkers = [];
        let waypoints = [
          {
            lat: data.request.origin['location'].lat(),
            lng: data.request.origin['location'].lng(),
            stopover: true,
          },
        ];

        data.request.waypoints.forEach((waypoint) => {
          waypoints.push({
            lat: waypoint.location['location'].lat(),
            lng: waypoint.location['location'].lng(),
            stopover: true,
          });
        });
        waypoints.push({
          lat: data.request.destination['location'].lat(),
          lng: data.request.destination['location'].lng(),
          stopover: true,
        });
        console.log('waypoints', waypoints);

        setTimeout(() => {
          waypoints.forEach((marker, index) => {
            // for the yellow order marker
            this.mapMarkers.push({
              position: {
                lat: marker.lat,
                lng: marker.lng,
              },
              options: {
                icon: {
                  url: 'https://imgoingcalendar.com/assets/imgs/tp-marker-2.png',
                  // anchor: new google.maps.Point(30, 50),
                  scaledSize: new google.maps.Size(25, 25),
                } as google.maps.Icon,
                zIndex: 10,
                // optimized: true,
              },
              label: {
                text: (++index).toString(),
                fontWeight: 'bold',
              },
            } as IMapMarkerWithCustomData<VisitorPlace>);
          });
        });
      });

    this.googleMap.drawDirectionRoute(
      this.directionLocations,
      google.maps.TravelMode.DRIVING,
      {
        optimizeWaypoints: true,
      }
    );
  }

  addMapMarker(lat, lng, label, id) {
    this.mapMarkers.push({
      position: {
        lat: lat,
        lng: lng,
      },
      options: {
        icon: {
          url: 'https://imgoingcalendar.com/assets/imgs/tp-marker-2.png',
          // anchor: new google.maps.Point(30, 50),
          scaledSize: new google.maps.Size(25, 25),
        } as google.maps.Icon,
        zIndex: 10,
      },
      label: {
        text: label.toString(),
        fontWeight: 'bold',
      },
      title: id,
    } as IMapMarkerWithCustomData<VisitorPlace>);
  }

  generatePlaceLink(
    place: VisitorPlace & { clientURL?: string; ImGoingLink: string }
  ): string {
    if (this.isScriptSolution) {
      return place.clientURL;
    }

    return place.ImGoingLink;
  }

  generateEventLink(event: VisitorEvent & { ImGoingLink: string }): string {
    if (this.isScriptSolution) {
      if (this.frontendSettings?.contacts?.eventCalendarURL) {
        return `${this.frontendSettings.contacts.eventCalendarURL}?imgoing-event=${event.nameId}`;
      }
    }

    return event.ImGoingLink;
  }

  get hasImg() {
    const answers = this.shadowRoot.querySelectorAll('.ig-im-answer');
    if (answers.length > 0) {
      const imgs = Array.from(answers).last().querySelectorAll('.ig-ai-img');
      return imgs.length > 0;
    }
    return false;
  }

  hideImg() {
    const placeImgs = this.shadowRoot.querySelectorAll('.ig-ai-place');
    const cards: HTMLElement[] = Array.from(placeImgs) as HTMLElement[];
    cards.forEach((ele) => {
      ele.classList.contains('hidden')
        ? ele.classList.remove('hidden')
        : ele.classList.add('hidden');
    });

    this.adjustMapHeight();
  }

  onMsgCreated(data: { lastMsg: ITravelAssistantMessage }) {
    console.log('onMsgCreated', data.lastMsg);
    this.msgHistory.push(data.lastMsg);
  }

  createMapMarkersForPlace(
    place: VisitorPlace | VisitorEvent
  ): IMapMarkerWithCustomData<VisitorPlace> {
    return {
      position: {
        lat: place.lat,
        lng: place.lng,
      },
      // label: place.name,
      title: place._id,
      data: place,
    } as IMapMarkerWithCustomData<VisitorPlace>;
  }

  createMapMarkersForEvent(
    event: VisitorEvent
  ): IMapMarkerWithCustomData<VisitorEvent> {
    return {
      position: {
        lat: event.address.lat,
        lng: event.address.lng,
      },
      // label: event.name,
      title: event._id,
      data: event,
    } as IMapMarkerWithCustomData<VisitorEvent>;
  }

  onMarkerClick(marker: IMapMarkerWithCustomData<VisitorPlace>) {
    console.log('onMarkerClick', marker);

    this.center = {
      lat: marker.position.lat,
      lng: marker.position.lng,
    } as google.maps.LatLngLiteral;

    const mapMarker = this.googleMap.mapMarkerObjs.find(
      (x) => x.getTitle() === marker.title
    );
    const place = this.places.find((x) => x._id === marker.title);

    if (place) {
      this.googleMap.showPlaceInfoWindow(mapMarker, {
        ...mapMarker,
        data: place as VisitorPlace,
      } as IMapMarkerWithCustomData<VisitorPlace>);
      return;
    }

    const event = this.events.find((x) => x._id === marker.title);

    if (event) {
      this.googleMap.showEventInfoWindow(mapMarker, {
        ...mapMarker,
        data: event as VisitorEvent,
      } as IMapMarkerWithCustomData<VisitorEvent>);
    }
  }

  expandScreen($event: boolean) {
    this.isScreenExpanded = $event;
  }
}
