<div class="image-uploader-wrapper" (drop)="onFileDrop($event)" (dragover)="onDragOver($event)">
  <input type="file" accept="image/png,image/gif,image/jpeg" (change)="onImageChange($event)" hidden #imageUploader />
  <div class="image-dropper" (click)="imageUploader.click()">
    <mat-icon *ngIf="!selectedImageData" class="translate-y-1">upload</mat-icon>
    <p class="text-xs text-center" *ngIf="!selectedImageData">Click or drag file to upload</p>
    <div *ngIf="selectedImageData">
      <img [src]="selectedImageData" alt="img" class="object-contain h-20">
      <div class="absolute flex justify-center items-center gap-4 delete-icon" (click)="removeImage($event)">
        <mat-icon style="color:white;width: auto;height: auto;">close</mat-icon>
      </div>
      <!-- <div *ngIf="image.status !== 'initial' && image.status !== 'done'"
        class="absolute flex flex-col justify-center items-center gap-2 autorenew-icon">
        <mat-icon>{{
          image.status === 'uploading' ? 'autorenew' : image.status === 'uploaded' ? 'check' :
          'clear'
          }}
        </mat-icon>
        <div class="flex gap-2">
          <span>{{ image.status }}</span>
          <span *ngIf="image.status==='failed'" class="link" (click)="uploadOwnedImagesToAWS()">Retry</span>
        </div>
      </div> -->
    </div>
  </div>