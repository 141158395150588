@if (travelBuddySettings && travelBuddySettings.indicator?.isEnabled && isUserChatHistoryReady) {
<div *ngIf="canShowIndicator"
  class="fixed rounded-md z-10 p-3 bg-white w-[26%] min-w-[240px] max-w-[320px] text-sm text-gray-600 mobile:!bottom-[120px] mobile:!right-[10px] ig-fade-in-down"
  [class.opacity-0]="isShow" [ngStyle]="{'right': right(), 'left': left(), 'bottom': indicatorBottom}"
  style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
  <mat-icon class="absolute -right-1.5 -top-2.5 !flex justify-center items-center rounded-full bg-white cursor-pointer"
    (click)="closeIndicator()">close
  </mat-icon>
  {{ travelBuddySettings.indicator?.prompt }}
</div>
}

<div *ngIf="travelBuddySettings && isUserChatHistoryReady"
  class="w-40 h-40 rounded-full flex items-center justify-center fixed border-[6px] border-[#d7d7d7]
  cursor-pointer z-[70] transition-all hover:scale-[1.07] mobile:w-[90px] mobile:h-[90px] mobile:!border-4 mobile:!right-2 mobile:!bottom-2 mobile:!animate-none"
  (click)="show()" [class.ig-color-flip]="!isShow" [class.!w-14]="isShow" [class.!h-14]="isShow"
  [class.!z-[50]]="isShow" [class.!border-none]="isShow"
  [ngStyle]="{'right': right(), 'bottom': bottom(), 'left': left(), 'top': top(), 'backgroundColor': travelBuddySettings.ui.color.toggleIcon}">

  <div [class.hidden]="isShow">
    <ig-image class="w-full transition-all duration-300 relative -top-8 flex justify-center mobile:-top-4"
      [src]="travelBuddySettings.ui.avatar.profile"
      cls="rounded-full !w-24 mobile:!w-10 mobile:max-w-[40px] mobile:max-h-[50px]"></ig-image>
    <div
      class="relative -top-6 text-white text-xs text-center font-semibold mobile:font-normal mobile:-top-4 mobile:text-[11px]">
      <div><span class="mobile:hidden">Chat now </span> <span class="mobile:hidden">with your</span></div>
      <div>AI <br class="hidden mobile:block">TravelBuddy!</div>
    </div>
  </div>
  <mat-icon *ngIf="isShow" class="text-white text-[26px] leading-[26px]">expand_more</mat-icon>
</div>

<div *ngIf="isShow" class="ig-im-mask fixed top-0 left-0 w-full h-full z-50 bg-transparent" (click)="show()"></div>

<div #container
  class="rounded-2xl fixed bg-white overflow-y-scroll max-w-[1280px] max-h-[85vh]
transition-all duration-300 z-[60] text-gray-600 flex flex-col shadow-lg to-65%
mobile:w-[94vw] mobile:max-w-[94vw] mobile:!right-[3%] mobile:max-h-[78vh] mobile:!bottom-[60px] smallDesktop:!bottom-[68px] ig-im-msg-box"
  [class.!bg-white]="msgHistory&&msgHistory.length"
  [style.backgroundImage]="'linear-gradient(to bottom, '+travelBuddySettings.ui.color.from+', '+travelBuddySettings.ui.color.to+')'"
  [class.h-0]="!isShow" [class.w-0]="!isShow" [class.opacity-0]="!isShow" [class.h-[700px]]="isShow"
  [class.w-[400px]]="isShow" [class.bg-gradient-to-b]="isShowLandscreen" [class.!overflow-y-hidden]="!isShowLandscreen"
  [class.!w-[calc(100%-96px)]]="isScreenExpanded" [class.!h-[calc(100%-200px)]]="isScreenExpanded"
  [ngStyle]="{'right': chatRight, 'bottom': chatBottom, 'left': chatLeft, 'top': chatTop}">

  <div *ngIf="isShowLandscreen" class="pb-5">
    <!-- <ig-logo cls="h-5 absolute left-4 top-3" fill="white"></ig-logo> -->
    <div class="absolute right-5 top-3">
      @if (isImg(travelBuddySettings.ui.logo)) {
      <img *ngIf="isImg(travelBuddySettings.ui.logo)" [src]="travelBuddySettings.ui.logo" alt="" class="h-10">
      } @else {
      <div style="font-family:monospace;letter-spacing: 1px" class="text-white text-sm">
        {{
        travelBuddySettings.ui.logo
        || hubName
        }}
      </div>
      }
    </div>

    <!-- <div class="im-bg w-full h-[150px] absolute left-0 top-0 -z-10 opacity-50 ig-test"
      style="background-image: url({{travelBuddySettings.ui.avatar.profile}});"></div> -->

    <ng-content select="[landscreen]"></ng-content>
  </div>

  <!-- <div [class.ig-hide]="!isShowLogin || isShowLandscreen || (isAppendLoginToChatArea && (!isAtbottom && hasScrollbar))" -->
  <!-- <div *ngIf="isAppendLoginToChatArea" [class.ig-img-append-login]="isAppendLoginToChatArea"
    class="absolute top-[308px] left-[54px] z-10">
    <p class="text-xs mb-3 text-center">Please login to remember your chatting context</p>
    <ig-social-login #socialLogin class="block" (onLoginStatusChanged)="loginStatusChanged($event)"></ig-social-login>
    <div>
      <hr class="mt-5">
      <div class="flex justify-center">
        <span class="relative -top-2 px-3 text-xs bg-white">OR</span>
      </div>

      <div class="mt-2 relative">
        <ig-field [control]="form.get('email')" label="Email" (keypress)="emailKeypress($event)"></ig-field>
        <div
          class="absolute right-2 top-2.5 p-2 text-blue-500 font-semibold cursor-pointer transition-all hover:scale-[1.1]"
          (click)="gotoChatWithEmail()">GO</div>
      </div>
    </div>
    <mat-checkbox class="w-[294px]" [(ngModel)]="isAllowTrackingChat">Would you allow us to record conversation content
      to
      enhance the functionality of the AI assistant?
    </mat-checkbox>
    <P class="text-xs mt-9 text-blue-600 flex justify-end cursor-pointer" (click)="anonymousUse()">Chat anonymously
      <mat-icon class="!w-4 text-right">chevron_right</mat-icon>
    </P>
  </div> -->

  <div class="pt-12 h-full bg-white" [class.!pt-0]="isShowLogin" [class.ig-hide]="isShowLandscreen">
    <div *ngIf="!isShowLogin"
      class="h-12 flex gap-5 items-center justify-between pl-3 fixed w-[400px] z-20 rounded-t-2xl -mt-12 max-w-[1280px] mobile:w-[94vw] ig-im-box-header"
      [class.!w-[calc(100%-96px)]]="isScreenExpanded" [style.backgroundColor]="travelBuddySettings.ui.color.from">
      <mat-icon (click)="backToLandScreen()" class="text-white text-2xl leading-5 cursor-pointer">chevron_left
      </mat-icon>
      <!-- <ig-logo cls="h-5 absolute left-11 top-3" fill="white"></ig-logo> -->
      <div class="absolute ml-8">
        @if (isImg(travelBuddySettings.ui.logo)) {
        <img *ngIf="isImg(travelBuddySettings.ui.logo)" [src]="travelBuddySettings.ui.logo" alt="" class="h-8">
        } @else {
        <div style="font-family:monospace;letter-spacing: 1px" class="text-white text-sm -mt-1">
          {{
          travelBuddySettings.ui.logo
          || hubName
          }}
        </div>
        }
      </div>
      <mat-icon class="right-2 cursor-pointer text-2xl relative -top-[7px] text-white mobile:!hidden"
        matTooltip="expand/shrink screen" (click)="expandScreen()">fullscreen
      </mat-icon>
    </div>

    <!-- <div *ngIf="isAskingAfterLogin&&!isAIRespond" class="flex flex-col justify-center h-60 text-white overflow-hidden"
      style="transition: all .7s;" [class.!h-0]="isAIRespond"
      [style.backgroundImage]="'linear-gradient(to bottom, '+travelBuddySettings.ui.color.from+', '+travelBuddySettings.ui.color.to+')'">
      <div class="flex justify-center gap-0">
        <div class="rounded-full bg-white w-12 h-12 outline outline-4"
          [style.outlineColor]="travelBuddySettings.ui.color.from">
          <div class="im-bot-faces" style="background-position: -8px -5px;"></div>
        </div>
        <div class="rounded-full bg-white w-12 h-12 outline outline-4 -ml-1"
          [style.outlineColor]="travelBuddySettings.ui.color.from">
          <div class="im-bot-faces" style="background-position: -65px 0px"></div>
        </div>
        <div class="rounded-full bg-white w-12 h-12 outline outline-4 -ml-1"
          [style.outlineColor]="travelBuddySettings.ui.color.from">
          <div class="im-bot-faces" style="background-position: -121px -71px"></div>
        </div>
      </div>
      <div class="w-4/5 mt-4 mx-auto text-center">
        <p class="font-semibold text-xl" [style.color]="travelBuddySettings.ui.color.text">
          {{travelBuddySettings.ui.avatar.name}}</p>
        <p class="text-[15px] mt-6" [style.color]="travelBuddySettings.ui.color.text">
          I'm your personal travel assistant. Simply ask me a question, I will provide
          you with relevant travel information and suggestions.</p>
      </div>
    </div> -->

    <div [class.!opacity-100]="isShowLogin||isAppendLoginToChatArea" [class.absolute]="isAppendLoginToChatArea"
      [class.!h-full]="isShowLogin||isAppendLoginToChatArea" [class.!pb-6]="isShowLogin||isAppendLoginToChatArea"
      class="h-0 opacity-0 top-0 z-10 pb-0 mobile:overflow-auto"
      [style.backgroundImage]="'linear-gradient(to bottom, '+travelBuddySettings.ui.color.from+', '+travelBuddySettings.ui.color.to+')'">

      <div class="flex justify-end pr-6" [class.pt-3]="isShowLogin||isAppendLoginToChatArea">
        @if (isImg(travelBuddySettings.ui.logo)) {
        <img *ngIf="isImg(travelBuddySettings.ui.logo)" [src]="travelBuddySettings.ui.logo" alt="" class="h-10">
        } @else {
        <div style="font-family:monospace;letter-spacing: 1px" class="text-white text-sm">
          {{
          travelBuddySettings.ui.logo
          || hubName
          }}
        </div>
        }
      </div>

      <div class="flex gap-5 mobile:justify-center">
        <ig-image
          class="w-24 relative flex justify-center ml-5 mobile:!ml-0 mobile:!w-20 mobile:max-h-[100px] smallDesktop:!w-20"
          [src]="travelBuddySettings.ui.avatar.profile" cls=""></ig-image>

        <div [style.color]="travelBuddySettings.ui.color.text" class="flex flex-col justify-end">
          <p class="text-2xl font-bold">Hi there, I'm your</p>
          <p class="text-2xl font-bold"> <span [style.color]="travelBuddySettings.ui.color.name">{{
              travelBuddySettings.ui.avatar.name }}</span>
          </p>
        </div>
      </div>

      <div>
        <p [style.color]="travelBuddySettings.ui.color.text" class="text-sm px-6 py-4">
          Login to save your conversation, and allow
          the Travel Buddy to provide answers with more context and an improved experience.</p>

        <div class="rounded-lg bg-white pt-7 pb-2 mx-6 smallDesktop:!pt-4 smallDesktop:!pb-0 smallDesktop:!mt-2">
          <ig-social-login #socialLogin class="block"
            (onLoginStatusChanged)="isShowLogin && loginStatusChanged($event)"></ig-social-login>

          <div>
            <hr class="!mt-5">
            <div class="flex justify-center">
              <span class="relative -top-2 px-3 text-xs bg-white">OR</span>
            </div>

            <div class="mt-2 relative mx-auto max-w-[294px] smallMobile:max-w-[260px]">
              <ig-field [control]="form.get('email')" label="Email" class="ig-tb-email"
                (keypress)="emailKeypress($event)" [hint]="emailErrorMsg"></ig-field>
              <div
                class="absolute right-2 top-2.5 p-2 text-blue-500 font-semibold cursor-pointer transition-all hover:scale-[1.1]"
                (click)="gotoChatWithEmail()">GO
              </div>
            </div>
          </div>

        </div>

        <div class="flex justify-center flex-col items-center">
          <!-- <mat-checkbox #allowTrackingCbox id="allow-tracking-cbox" class="mt-3" [(ngModel)]="isAllowTrackingChat"
            [style.color]="travelBuddySettings.ui.color.text">Yes, I agree to share my
            email address
          </mat-checkbox> -->
          <p class="px-6 pt-6 pb-4 text-[11px] ig-tb-login-tip" [style.color]="travelBuddySettings.ui.color.text">
            When you login, you agree to share your email address with the destination marketing team. We may email you
            events, things to do and travel information to help you plan your next trip! You can unsubscribe anytime and
            review our
            <a class="!underline" [href]="travelBuddySettings.ui.otherInfo.privacyPolicyLink" target="_blank"
              [style.color]="travelBuddySettings.ui.color.text">Privacy
              Policy</a> for more
            details on how we manage user data.
          </p>
          <P class="text-xs text-blue-600 flex justify-end cursor-pointer underline underline-offset-2 ig-tb-chat-anony"
            [style.color]="travelBuddySettings.ui.color.text" (click)="anonymousUse()">Or chat anonymously&nbsp;
            <span style="letter-spacing: -3px">>></span>
          </P>
        </div>
      </div>

      <div *ngIf="isLoadingUserChatHistory"
        class="w-full h-full flex justify-center items-center absolute top-0 left-0 bg-[#ffffff87]">
        <ig-spinner></ig-spinner>
      </div>
    </div>


    <!-- to cover the weird blinking when above section is folded -->
    <div class="h-5 w-full absolute z-[99999] bg-white" [class.hidden]="isInNormalChat"></div>

    <div #chatContainer style="backdrop-filter: blur(8px);height:calc(100% - 48px);"
      class="w-full rounded-2xl overflow-y-auto scroll-smooth bg-white" [class.overflow-y-hidden]="isFolding"
      [class.pb-[420px]]="isAppendLoginToChatArea">

      <div *ngFor="let msg of msgHistory">
        <div *ngIf="msg.type === 'DATETIME'" class="my-4 text-gray-500 text-center">
          <span class="py-1 px-1.5 rounded-xs bg-gray-100 text-xs">{{ msg.msg }}</span>
        </div>
        <ig-msg-box *ngIf="msg.type !== 'DATETIME'" [message]="msg" [errorMsg]="msg.errorMsg"
          [avatar]="userProfilePicture" [letterAvatar]="userEmail" [loadingTips]="loadingTips" [isAllowClick]="true"
          (avatarClicked)="logout()" [isScreenExpanded]="isScreenExpanded" [travelBuddySettings]="travelBuddySettings"
          (ask)="onSendClick.emit($event)" (tryAgain)="onTryAgain.emit($event)" class="animate-bottom-up">
          <ng-container adding>
            <ng-content select="[adding]"></ng-content>
          </ng-container>
        </ig-msg-box>
        <ng-content *ngIf="msg.type==='AI'" select="[addition]"></ng-content>
      </div>
    </div>

    <!-- <div class="h-12 mx-5 absolute bottom-4 rounded-lg outline outline-1 outline-[#d3d3d3b3]"
      style="width: calc(100% - 40px);">
      <div class="w-full h-full relative flex justify-between items-center">
        <input type="text" [(ngModel)]="msg" (keypress)="keypress($event)" [class.opacity-0]="!isShow"
          placeholder="Write a question..."
          class="h-full w-full bg-[white] text-base flex items-center px-5 rounded-lg outline-none pr-[58px]">
        <mat-icon class="absolute right-2 top-4 text-cyan-500 cursor-pointer" (click)="send()">send</mat-icon>
      </div>
    </div> -->

    <div [class.!hidden]="isShowLogin&&!isAIRespond"
      class="w-full h-12 flex justify-between items-center absolute bottom-0 rounded-b-lg border-t border-solid border-gray-200">
      <input type="text" [(ngModel)]="msg" (keypress)="keypress($event)" [class.opacity-0]="!isShow"
        placeholder="Write a question..."
        class="h-full w-full bg-[white] text-base flex items-center px-5 rounded-lg outline-none pr-[58px]">
      <mat-icon class="absolute right-2 top-4 text-cyan-500 cursor-pointer" (click)="onSendClick.emit(msg)">send
      </mat-icon>
    </div>
  </div>
</div>
