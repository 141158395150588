export const routeConfigs = {
  auth: {
    path: 'auth',
    login: { path: '' },
  },
  visitor: {
    path: ':hubName',
    eventCalendar: {
      path: 'events',
      eventMap: { path: 'map' },
      oneRow: { path: 'one-row' },
      createEvent: { path: 'submit' },
      editorChoice: { path: 'events-editor-choice' },
      editorChoiceOneRow: { path: 'events-editor-choice/:mode' },
      favEvent: { path: 'custom-events/:customEventBtnId' },
      favEventOneRow: { path: 'custom-events/:customEventBtnId/:mode' },
      locationFilter: { path: 'location/:locationId' },
      groupLocationFilter: { path: 'location/group/:groupId' },
    },

    /** first eventDetailsModal is for lazying loading */
    eventDetailsModal: { path: 'details/:eventId' },
    // eventDetailsModal: { path: 'events/details/:eventId' },
    // eventMap: { path: 'events/map' },
    // createEvent: { path: 'events/create' },

    placeCalendar: {
      path: 'places',
      category: { path: 'category/:category' },
      placeMapAll: { path: 'map' },
      placeMap: { path: 'map/:category' },

      oneRow: { path: 'one-row' },

      // pathList: 'places/:category/:menuType',
      createPlace: { path: 'submit' },

      favCustomPlace: { path: 'fav/:favCustomPlaceBtnId' },
      favCustomPlaceOneRow: { path: 'fav/:favCustomPlaceBtnId/:mode' },
      customDefaultPlace: { path: 'custom/:categoryId' },
      customDefaultPlaceOneRow: { path: 'custom/:categoryId/one-row' },

      editorChoice: { path: 'editor-choice' },
      editorChoiceOneRow: { path: 'editor-choice/:mode' },

      placeDetailsModal: { path: 'details/:placeId' },
      locationFilter: { path: 'location/:locationId' },
      groupLocationFilter: { path: 'location/group/:groupId' },
    },

    ai: {
      path: 'ai',
      travelBuddy: { path: 'travel-buddy' },
    },

    /** for compatible with V2 routes */
    favCustomPlace: {
      path: 'custom-places/:favCustomPlaceBtnId',
    },
    defaultPlace: {
      path: 'places/:category',
    },
    customDefaultPlace: {
      path: 'places/:categoryId/custom',
    },
    placeMapFavCustomV2: {
      path: 'places-map/custom-places/:category',
    },
  },
  tripPlanner: {
    path: ':hubName',

    itinerary: { path: 'itineraries', editMode: { path: 'edit' } },
    tripPlanner: { path: 'trip-planner' },
    /** for compatible with V2 routes */
    tour: { path: 'trips/tour-list' },
    singleTour: { path: 'trips/tour/:itineraryId' },
    trips: { path: 'trips' },
  },
  ai: {
    path: ':hubName/ai',
    travelBuddy: { path: 'travel-buddy' },
  },
};

export function getRouteUrl(route: any, parameters?: any): string {
  let routeParameters = [];
  let config = (route.config || []).slice();

  if (parameters) {
    config.forEach((path, index) => {
      if (path.startsWith(':')) {
        routeParameters.push({ path, index });
      }
    });

    for (let parameter of routeParameters) {
      config[parameter.index] =
        parameters[parameter.path.substr(1, parameter.length)];
    }
  }

  let parentUrl = route.parent ? getRouteUrl(route.parent, parameters) : '';
  let childUrl = config.join('/');

  if (childUrl) {
    parentUrl += '/';
  }

  return `${parentUrl}${childUrl}`;
}

function setRouteConfigRelationship(child: any, parent: any) {
  for (let propName in child) {
    if (propName !== 'path' && child.hasOwnProperty(propName)) {
      setRouteConfigRelationship(child[propName], child);
    }
  }

  child.parent = parent;
  child.config = child.path.split('/');
}

for (let propName in routeConfigs) {
  if (routeConfigs.hasOwnProperty(propName)) {
    setRouteConfigRelationship(routeConfigs[propName], routeConfigs);
  }
}
