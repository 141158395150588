import { EventTimeService } from './../../../services/event.time.service';
import {
  ImageViewerModalComponent,
  ImageViewerModalData,
} from './../../../../../common/components/image.viewer.modal/image.viewer.modal.component';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ErrorAlertService } from 'src/app/common/services/error.alert/error.alert.service';
import { SeoService } from 'src/app/common/services/seo.service/seo.service';
import { VisitorEventService } from '../../../services/visitor.event.service';
import { IEventDetailsModalData } from '../event.details.modal.provider';
import { EventDetailsBaseComponent } from '../event.details.base.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from 'src/app/common/services/modal/modal.service';
import {
  IAppState,
  IFrontendSettings,
} from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import { select, Store } from '@ngrx/store';
import { frontendSettingsSelector } from 'src/app/modules/shared/ngrx.stores/frontend.settings/selectors';
import { VisitorPlaceService } from '../../../services/visitor.place.service';
import { VisitorPlace } from 'src/app/modules/shared/models/visitor.place';
import { FancyPlaceDetailsModalComponent } from 'src/app/modules/place-calendar/components/place.details.modal/fancy.place.details.modal/fancy.place.details.modal.component';
import { IPlaceDetailsModalData } from 'src/app/modules/place-calendar/components/place.details.modal/place.details.modal.provider';
import { GoogleMapComponent } from 'src/app/modules/shared/components/google.map/google.map.component';
import { VisitorEvent } from 'src/app/modules/shared/models/visitor.event';
import { NearbyPlacesComponent } from 'src/app/modules/shared/components/nearby.places/nearby.places.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { LocalStorageService } from 'src/app/modules/shared/services/local.storage.service';
import { IMapMarkerWithCustomData } from 'src/app/modules/shared/models/place.marker';
import { ScriptService } from 'src/app/common/services/load.script/load.script.service';
import { tap, timer } from 'rxjs';

@Component({
  selector: 'ig-fancy-event-details-modal',
  templateUrl: './fancy.event.details.modal.component.html',
  styleUrls: ['./fancy.event.details.modal.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class FancyEventDetailsModalComponent extends EventDetailsBaseComponent {
  settings: IFrontendSettings;
  @ViewChild('map', { static: false }) googleMap: GoogleMapComponent;
  @ViewChild('nearbyPlacesCom', { static: false })
  nearbyPlacesCom: NearbyPlacesComponent;

  isCssReady = false;

  constructor(
    public override activatedRoute: ActivatedRoute,
    public override eventService: VisitorEventService,
    public override placeService: VisitorPlaceService,
    public override seoService: SeoService,
    public override errorAlert: ErrorAlertService,
    public override sanitizer: DomSanitizer,
    public modalService: ModalService,
    public timeService: EventTimeService,
    public localStorageService: LocalStorageService,
    public scriptService: ScriptService,
    public cdRef: ChangeDetectorRef,
    public override dialogRef: MatDialogRef<FancyEventDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public override data: IEventDetailsModalData,
    private _store: Store<IAppState>
  ) {
    super(
      activatedRoute,
      eventService,
      placeService,
      seoService,
      errorAlert,
      sanitizer,
      dialogRef,
      data
    );

    this._store.pipe(select(frontendSettingsSelector)).subscribe((settings) => {
      settings && (this.settings = settings);
    });

    // handle browser back button click, close the event details view and reload the page
    window.addEventListener('popstate', this.hashChanged.bind(this));

    // load the html editor css style so that the customized description style can be loaded
    const sunEditorCssUrl = `https://cdn.jsdelivr.net/npm/suneditor@latest/dist/css/suneditor.min.css`;
    const selector = '.cdk-overlay-container ig-fancy-event-details-modal';

    // as this modal is ShadowDom, we need to inject the styles into it
    dialogRef.afterOpened().subscribe(() => {
      modalService.injectStyle(selector, [sunEditorCssUrl], () => {
        this.isCssReady = true;
        timer(200)
          .pipe(
            tap(() => {
              // for some clients use Wix. One upgrade of Wix causes the list not to be updated. Refer to https://itidigital.atlassian.net/browse/DXP-2040
              this.cdRef.detectChanges();
            })
          )
          .subscribe();
      });
    });
  }

  isVagueImage = false;

  get targetForNearbyPlaces() {
    return {
      hubName: this.hubName,
      lat: this.event.address.lat,
      lng: this.event.address.lng,
    } as VisitorPlace;
  }

  getFrom(): string {
    switch (true) {
      case this.data.hasOwnProperty('currentEvent'):
        return 'EVENTS';
      case this.data.from === 'placeCalendar':
        return 'PLACES';
      case this.data.from === 'itinerary':
        return 'ITINERARY';
      case this.data.from === 'nearbyOnPlace':
        return 'PLACE';
      default:
        return 'BACK';
    }
  }

  public toggleShowNearbyPlacesOnMap(status: MatCheckboxChange) {
    if (status.checked) {
      this.isShowingNearbyPlacesOnMap = false;

      this.nearbyPlacesCom.nearByPlaces$.subscribe((places) => {
        const markers = places.map(
          (x) =>
            ({
              position: { lat: x.address.lat, lng: x.address.lng },
              options: {
                icon: 'https://iti-images.s3.amazonaws.com/imgs/place-48.png',
                zIndex: 2,
              },
              data: x,
            } as IMapMarkerWithCustomData<VisitorPlace | VisitorEvent>)
        );

        this.keepPlaceMapMarker();
        this.mapMarkers = [...this.mapMarkers, ...markers];
        this.isShowingNearbyPlacesOnMap = true;
      });
    } else {
      this.keepPlaceMapMarker();
      this.isShowingNearbyPlacesOnMap = false;
    }
  }

  // serp google events images are vague, in this case show the image in middle and use a weaken background
  checkImageVague($event: Event) {
    const ele = $event.target || $event['path'][0];
    const coverImgNaturalWidth = ele['naturalWidth'];
    this.isVagueImage = coverImgNaturalWidth && coverImgNaturalWidth < 500;
  }

  viewImage() {
    this.modalService.show<ImageViewerModalComponent>({
      component: ImageViewerModalComponent,
      data: {
        imgs: [this.event.cover.source],
      } as ImageViewerModalData,
      panelClass: 'ig-transparent-modal',
    });
  }

  get favCustomCategories() {
    const btns = this.settings.customSettings.customEvents?.filter((fav) =>
      fav.events.includes(
        this.data.currentEvent?._id || this.data.eventDetailsId
      )
    );
    if (!btns || !btns.length) return [];
    return btns?.map((x) => x.btnText);
  }

  goToPlace(place: VisitorPlace) {
    this.modalService.show({
      component: FancyPlaceDetailsModalComponent,
      panelClass: 'ig-modal-w-full',
      data: {
        currentPlace: place,
        from: 'eventCalendar',
      } as IPlaceDetailsModalData,
    });
  }

  markerClicked(marker: IMapMarkerWithCustomData<VisitorEvent | VisitorPlace>) {
    if (marker.data._id === this.event._id) {
      this.googleMap.closeInfoWindow();
      return;
    }
    if (this.isShowingNearbyPlacesOnMap) {
      this.googleMap.showPlaceInfoWindow();
    } else {
      this.googleMap.showEventInfoWindow();
    }
  }

  private keepPlaceMapMarker() {
    this.mapMarkers = this.mapMarkers.filter(
      (x) => x.data._id === this.event._id
    );
  }

  /**
   * handle browser back button click, close the event details page
   * only close the details page is not enough, it will always have other loading issues for the script embedding solution
   * so force reload the page for now
   * */
  // @HostListener('window:popstate', ['$event'])
  hashChanged($event: Event) {
    $event.preventDefault();
    let url = window.location.href.split('imgoing-event')[0];
    url = url.endsWith('?') ? url.substring(0, url.length - 1) : url;
    window.location.href = url;
    this.close();
  }
}
