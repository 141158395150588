import { BaseComponent } from './../../../../common/components/base.component';
import { DateRangePickerComponent } from 'src/app/common/components/date.range.picker/date.range.picker.component';
import { AccordionComponent } from 'src/app/common/components/accordion/accordion.component';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IDateRange } from 'src/app/common/components/date.range.picker/date.range';
import { CalendarQueryCommand } from 'src/app/modules/shared/models/calendar.action.command';
import { VisitorEventService } from '../../services/visitor.event.service';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { eventQueryCommandSelector } from '../../ngrx.stores/event.query.command/selectors';
import { Location } from '@angular/common';

@Component({
  selector: 'ig-event-date-filter',
  templateUrl: './event.date.filter.component.html',
  styleUrls: ['./event.date.filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EventDateFilterComponent extends BaseComponent {
  @Input() mode: 'expand' | 'dropdown' = 'expand';
  @Input() menuClass: string = '';
  @ViewChild('accordion', { static: true }) accordion: AccordionComponent;
  @ViewChild('dateRangePicker', { static: false })
  dateRangePicker: DateRangePickerComponent;

  isShowSpecificDates = false;
  title = 'FILTER BY DATE';

  originLink: string = '';

  @Output() dateSelected = new EventEmitter<{ from: Date; to: Date, dateRange: string }>();

  constructor(
    public activatedRoute: ActivatedRoute,
    private _eventService: VisitorEventService,
    private _router: Router,
    private _location: Location,
    private _store: Store<IAppState>
  ) {
    super({ activatedRoute });

    this._store
      .pipe(takeUntil(this.isDestroyed), select(eventQueryCommandSelector))
      .subscribe((cmd: CalendarQueryCommand) => {
        if (!cmd) return;

        if (cmd.dateRange) {
          switch (cmd.dateRange) {
            case 'today':
              this.title = "TODAY";
              break;
            case 'tomorrow':
              this.title = "TOMORROW";
              break;
            case 'this-week':
              this.title = "THIS WEEK";
              break;
            case 'this-weekend':
              this.title = "THIS WEEKEND";
              break;
            case 'this-month':
              this.title = "THIS MONTH";
              break;
            default:
              this.title = 'FILTER BY DATE';
              break;
          }

          const currentPath = this._location.path();
          if (currentPath.includes('date=')) {
            this._location.go(currentPath.split('date=')[0]
              + `date=${cmd.dateRange}`);
          } else {
            const connector = currentPath.includes('?') ? '&' : '?';
            this._location.go(currentPath + `${connector}date=${cmd.dateRange}`);
          }
        }
      });
  }

  dateRangeChanged(dateRange: IDateRange) {
    const from =
      this.mode === 'expand'
        ? new Date(dateRange.start).toLocaleDateString()
        : new Date(dateRange.start).toLocaleDateString().substring(0, 5);
    const to =
      this.mode === 'expand'
        ? new Date(dateRange.end).toLocaleDateString()
        : new Date(dateRange.end).toLocaleDateString().substring(0, 5);

    this.title = 'FROM ' + from + ' TO ' + to;
    this.accordion.closeMenu();

    this.changeDate('specific', dateRange);
  }

  changeDate(date: string, dateRange?: IDateRange) {
    const command = new CalendarQueryCommand();
    let range: { from: Date; to: Date };

    switch (date) {
      case 'all':
        break;
      case 'today':
        range = this.getTodayRange();
        break;
      case 'tomorrow':
        range = this.getTomorrowRange();
        break;
      case 'this-week':
        range = this.getWeekRange();
        break;
      case 'this-weekend':
        range = this.getWeekendRange();
        break;
      case 'this-month':
        range = this.getMonthRange();
        break;
      case 'specific':
        range = this.getSpecificRange(dateRange);
        break;
      default:
        throw Error('Invalid date range');
    }

    if (range) {
      command.from = range.from;
      command.to = range.to;
    }

    if (date !== 'specific') {
      this.title = date === 'all' ? 'FILTER BY DATE' : date.toUpperCase();
      this.dateRangePicker?.reset();
      this.isShowSpecificDates = false;
    }

    this.dateSelected.emit({
      from: command.from,
      to: command.to,
      dateRange: date,
    });
  }

  // Helper methods for date calculations
  private getTodayRange(): { from: Date; to: Date } {
    const from = new Date(new Date().setHours(0, 0, 0, 0));
    const to = new Date(new Date().setHours(24, 0, 0, 0));
    return { from, to };
  }

  private getTomorrowRange(): { from: Date; to: Date } {
    const { from, to } = this.getTodayRange();
    return {
      from: new Date(from.setDate(from.getDate() + 1)),
      to: new Date(to.setDate(to.getDate() + 1)),
    };
  }

  private getWeekRange(): { from: Date; to: Date } {
    const today = new Date();
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - today.getDay() + 2);
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 5);
    endOfWeek.setHours(23, 59, 59, 999);

    return { from: startOfWeek, to: endOfWeek };
  }

  private getWeekendRange(): { from: Date; to: Date } {
    const today = new Date();
    const daysUntilFriday = (5 - today.getDay() + 7) % 7;
    const startOfWeekend = new Date(today);
    startOfWeekend.setDate(today.getDate() + daysUntilFriday);
    startOfWeekend.setHours(23, 59, 59, 999);

    const endOfWeekend = new Date(startOfWeekend);
    endOfWeekend.setDate(startOfWeekend.getDate() + 2);
    endOfWeekend.setHours(23, 59, 59, 999);

    return { from: startOfWeekend, to: endOfWeekend };
  }

  private getMonthRange(): { from: Date; to: Date } {
    const today = new Date();
    return {
      from: new Date(today.getFullYear(), today.getMonth(), 1),
      to: new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0,
        23,
        59,
        59,
        999
      ),
    };
  }

  private getSpecificRange(dateRange: IDateRange): { from: Date; to: Date } {
    return {
      from: new Date(new Date(dateRange.start).setHours(0, 0, 0, 0)),
      to: new Date(new Date(dateRange.end).setHours(24, 0, 0, 0)),
    };
  }

  clear(isEmitEvent: boolean = true) {
    isEmitEvent ? this.changeDate('all') : (this.title = 'FILTER BY DATE');
    this.dateRangePicker?.reset();
  }
}
